import { authFetch, Config, TokenStore } from '@collab/utils/api';
import {
  getAccessToken,
  getRefreshToken,
  setTokenCookie,
} from '@collab/utils/session';

import { backendApiBaseUrl } from './api';
import { refreshTokens } from './auth/auth.api';

const tokenStore: TokenStore = {
  getAccessToken: getAccessToken,
  getRefreshToken: getRefreshToken,
  setTokenSet: setTokenCookie,
};

const config: Config = {
  baseUrl: backendApiBaseUrl,
  refreshTokens,
  tokenStore,
};

export default authFetch(config);
