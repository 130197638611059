import { HeaderProps } from '@collab/organisms';
import { defaultLinks } from '@collab/organisms/Footer/Footer';
import { environment } from '@collab/utils/environment';

import { designSupportEmail } from 'config/config';

export const header: HeaderProps = {
  withInfoBanner: false,
  navItems: [
    { text: 'Design', href: '/', active: true },
    { text: 'Develop', href: environment.DEVELOPER_PORTAL_URL },
  ],
};

export const footer = {
  links: {
    contact: { text: 'Contact', href: `mailto:${designSupportEmail}` },
    ...defaultLinks,
  },
};

export const dictionaries = {
  'VideoPlayer.controls.pause': 'pause',
  'VideoPlayer.controls.play': 'play',
};
