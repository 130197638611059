import { environment } from '@collab/utils/environment';

import beforeSend from './beforeSend';
import type { BrowserOptions } from '@sentry/nextjs';

const SENTRY_DSN =
  'https://2a96813ff45842119032ba7ee9cbeff8@sentry-relay-weu.volvocars.biz/4504650605068288';

export const SENTRY_ENABLED = process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true';

export const CONFIG: BrowserOptions = {
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: environment.NAME,
  release: process.env.NEXT_PUBLIC_RELEASE_ID,
  beforeSend,
};
