import Script from 'next/script';

import { ConsentGroups } from '@vcc-www/storage/consent';

/** OneTrust Cookie Consent Notice */
export const OneTrustScript: React.FC<{
  optanonUrl: string;
  dataDomainScript: string;
}> = ({ optanonUrl, dataDomainScript }) => (
  <Script
    id="one-trust"
    src={optanonUrl}
    data-language="en"
    type="text/javascript"
    data-domain-script={dataDomainScript}
    strategy="beforeInteractive"
  />
);

export const GTMTagScript: React.FC<{
  gtmId: string;
}> = ({ gtmId }) => (
  <Script id={gtmId}>
    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gtmId}');`}
  </Script>
);

/** noscript works only in browsers when JavaScript is off */
export const GTMTagIframeScript: React.FC<{
  gtmId: string;
}> = ({ gtmId }) => (
  <noscript
    dangerouslySetInnerHTML={{
      __html: `<iframe title="GTM" src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
    }}
  />
);

export const FUNCTIONAL_COOKIES_EVENT = 'functionalCookiesApproved';

/** Once consent is given, the tag will be converted to a js tag and executed */
export const FunctionalCookiesScript = () => (
  <Script
    id="functional-cookies"
    type="text/plain"
    className={`optanon-category-${ConsentGroups.FUNCTIONAL}`}
  >
    {`window.dispatchEvent(new CustomEvent('${FUNCTIONAL_COOKIES_EVENT}'));`}
  </Script>
);
