import {
  DefaultSeoProps,
  getDefaultSeoComponent,
  getPageSeoComponent,
  SeoProps,
} from '@collab/libs/seo';

const seoUrl = 'https://design.volvocars.com';

const defaultProps: DefaultSeoProps = {
  defaultTitle: 'Volvo Cars Design Portal',
  description:
    'Our design guidelines cover the fundamentals of grids, type, colour, iconography, photography and more.',
  defaultImageSrc: '/images/design_hero.jpg',
};

const PageSeo = getPageSeoComponent(seoUrl);
const DefaultSeoBase = getDefaultSeoComponent(seoUrl);

export const DefaultSeo = () => <DefaultSeoBase {...defaultProps} />;

export type { SeoProps };

export default PageSeo;
