import { accountPath } from '@collab/libs/auth/authPaths';
import getValidRedirectUrl, {
  removeTrailingSlashes,
} from '@collab/utils/getValidRedirectUrl';

const protectedUrls = removeTrailingSlashes([accountPath]);

const getNonProtectedUrl = (url: string): string =>
  getValidRedirectUrl(url, protectedUrls);

export { getNonProtectedUrl };
