'use client';

import React, { createContext, useContext, useReducer } from 'react';
import { CurrentTheme, useTheme, ThemeBreakpointName } from 'vcc-ui';
import { useLayoutEffect } from './useLayoutEffect';

export type BreakpointsMatchMap = {
  [key in ThemeBreakpointName]: boolean;
};

/**
 * Get the initial breakpoint match values.
 *
 * These values will be used both in server side rendering and on the first client
 * side render, before the first matchMedia run. This means we do not get rehydration
 * warnings because the first client side rendering matches the server side rendering.
 */
function getInitialState(theme: CurrentTheme): BreakpointsMatchMap {
  const matches = fromEntries(
    Object.entries(theme.breakpoints).map(([name]) => [name, false]),
  ) as BreakpointsMatchMap;
  // Default to assuming mobile viewport because the time between a default render
  // and the final state will take longer on slower mobile devices then on desktop.
  matches.onlyS = matches.untilM = matches.untilL = matches.untilXL = true;
  return matches;
}

const BreakpointsContext = createContext<BreakpointsMatchMap>(
  {} as BreakpointsMatchMap,
);

function breakpointsReducer(
  state: BreakpointsMatchMap,
  action: { name: ThemeBreakpointName; matches: boolean },
) {
  if (state[action.name] === action.matches) {
    return state;
  }
  return {
    ...state,
    [action.name]: action.matches,
  };
}

/**
 * Provider for tests to always return the select breakpoint matches.
 */
export const StaticBreakpointsProvider: React.FC<
  React.PropsWithChildren<{
    breakpoints?: Partial<BreakpointsMatchMap>;
  }>
> = ({ children, breakpoints }) => {
  const theme = useTheme();

  const matches = breakpoints
    ? (fromEntries(
        Object.entries(theme.breakpoints).map(([name]) => [
          name,
          breakpoints[name as keyof BreakpointsMatchMap] || false,
        ]),
      ) as BreakpointsMatchMap)
    : getInitialState(theme);
  return (
    <BreakpointsContext.Provider value={matches}>
      {children}
    </BreakpointsContext.Provider>
  );
};

export const BreakpointsProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const theme = useTheme();
  const [breakpoints, dispatch] = useReducer(
    breakpointsReducer,
    getInitialState(theme),
  );

  useLayoutEffect(() => {
    if (!window.matchMedia) return;

    // Invoke matchMedia and define change listeners for each breakpoint
    const mqHandlers = Object.entries(theme.breakpoints).map(
      ([name, query]) => ({
        name: name as ThemeBreakpointName,
        mql: window.matchMedia(query.replace(/^@media /, '')),
        handler(event: MediaQueryListEvent) {
          dispatch({
            name: name as ThemeBreakpointName,
            matches: event.matches,
          });
        },
      }),
    );
    mqHandlers.forEach(({ name, mql, handler }) => {
      // Dispatch the initial matches for each media query to update the state
      dispatch({ name, matches: mql.matches });
      mql.addListener(handler);
    });
    return () => {
      mqHandlers.forEach(({ mql, handler }) => mql.removeListener(handler));
    };
  }, [theme]);

  return (
    <BreakpointsContext.Provider value={breakpoints}>
      {children}
    </BreakpointsContext.Provider>
  );
};

/**
 * Defaults to small (<480) viewport on server side rendering.
 */
export const useBreakpoints = () => useContext(BreakpointsContext);

// Object.fromEntries not available in Node <12
function fromEntries<T, K extends PropertyKey>(entries: Iterable<[K, T]>) {
  return [...entries].reduce(
    (obj, [key, val]) => {
      obj[key as K] = val;
      return obj;
    },
    {} as Record<K, T>,
  );
}
