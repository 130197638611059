import { useUserContext } from '@collab/features/user';

import useClearUserData from './useClearUserData';

const useForceLogout = () => {
  const { setUserStatus } = useUserContext();
  const clearUserData = useClearUserData();

  const forceLogout = () => {
    setUserStatus('logged-out');
    clearUserData();
  };

  return forceLogout;
};

export default useForceLogout;
