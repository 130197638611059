import { MenuContent } from '@collab/organisms/NavMenu';
import { environment } from '@collab/utils/environment';

export const menuContent: MenuContent = {
  heading: 'design system',
  footer: {
    heading: 'develop',
    link: {
      label: 'Visit Developer site',
      href: environment.DEVELOPER_PORTAL_URL,
    },
  },
};
