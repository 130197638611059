import { useSWRConfig } from 'swr';

import { clearLoginSession } from '@collab/utils/session';

const useClearUserData = () => {
  const { cache } = useSWRConfig();

  const clearUserData = () => {
    clearLoginSession();
    if (cache instanceof Map) {
      cache.clear();
    }
  };

  return clearUserData;
};

export default useClearUserData;
