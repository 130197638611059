import { useRouter } from 'next/router';

import { useUserContext } from '@collab/features/user';
import { logException } from '@collab/utils/logger';

import { oAuthLogout } from 'libs/auth/redirectLogin';
import { getNonProtectedUrl } from 'libs/auth/redirectValidation';
import useClearUserData from 'libs/users/useClearUserData';

const useLogout = () => {
  const router = useRouter();

  const { setUserStatus } = useUserContext();
  const clearUserData = useClearUserData();

  const logout = async (redirectUrl: string = window.location.pathname) => {
    setUserStatus('logging-out');

    try {
      await oAuthLogout();
    } catch (e) {
      logException(e);
    } finally {
      clearUserData();
      await router.replace(getNonProtectedUrl(redirectUrl));
      setUserStatus('logged-out');
    }
  };

  return logout;
};

export default useLogout;
