import { ConfigProvider, StyleRenderer } from 'vcc-ui';

import { PopupProvider } from '@collab/features/popup';
import { ToastProvider } from '@collab/features/toasts';
import { UserProvider } from '@collab/features/user';
import PageLayout, { PageMods } from '@collab/layouts/PageLayout';
import { MenuItemModel } from '@collab/organisms/NavMenu';
import { TrackingProvider } from '@collab/providers/TrackingProvider';
import ErrorBoundary from '@collab/setup/ErrorBoundary';
import commonVccUiConfig from '@collab/setup/commonVccUiConfig';
import GlobalStyleProvider from '@collab/style/GlobalStyleProvider';
import ComposeComponents from '@vcc-www/compose-components';
import { BreakpointsProvider } from '@vcc-www/hooks';

import { footer, header } from 'content/appContent';
import MenuConfig from 'features/menu/MenuConfig';
import ErrorConfig from 'libs/errors/ErrorConfig';
import SWRConfig from 'libs/swr/SWRConfig';

type AppProvidersProps = {
  renderer: StyleRenderer;
  pageMods: PageMods | undefined;
  menuItems: MenuItemModel[];
  children: React.ReactNode;
};

const AppProviders: React.FC<AppProvidersProps> = ({
  menuItems,
  pageMods = {},
  renderer,
  children,
}) => {
  const themeVariant = pageMods?.darkTheme ? 'dark' : 'light';

  return (
    <ComposeComponents
      components={[
        [GlobalStyleProvider, { renderer, themeVariant }],
        [ConfigProvider, { config: commonVccUiConfig }],
        [ErrorBoundary],
        [TrackingProvider],
        [ToastProvider],
        [PopupProvider],
        [SWRConfig],
        [UserProvider],
        [ErrorConfig],
        [MenuConfig, { menuItems }],
        [BreakpointsProvider],
        [PageLayout, { pageMods, layoutProps: { header, footer } }],
      ]}
    >
      {children}
    </ComposeComponents>
  );
};

export default AppProviders;
