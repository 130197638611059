import { MenuItemModel, MenuStateProvider } from '@collab/organisms/NavMenu';

import { menuContent } from 'content/menuContent';
import useAkamaiAuth from 'features/akamai-auth/useAkamaiAuth';

import useAccount from './impl/useAccount';

const MenuConfig: React.FC<{
  menuItems: MenuItemModel[];
  children: React.ReactNode;
}> = ({ menuItems, children }) => {
  const account = useAccount();
  const contentGuard = useAkamaiAuth();

  return (
    <MenuStateProvider
      items={menuItems}
      content={menuContent}
      account={account}
      contentGuard={contentGuard}
    >
      {children}
    </MenuStateProvider>
  );
};

export default MenuConfig;
