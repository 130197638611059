import { environment } from '@collab/utils/environment';

const dataDomainId = 'a041f140-420c-4057-8953-66fed2480877';
const dataDomainScript = `${dataDomainId}${environment.IS_PROD ? '' : '-test'}`;
const optanonUrl = `https://cdn.cookielaw.org/consent/${dataDomainScript}/otSDKStub.js`;
export const oneTrust = { dataDomainScript, optanonUrl };

export const gtmId = 'GTM-P26CK5V';

export const designSupportEmail = 'ux.design.guidelines@volvocars.com';
