import { AuthToken, normalizeAuthToken } from '@collab/libs/auth/authModels';
import {
  ExchangeType,
  LoginParams,
  toSearchParams,
} from '@collab/libs/auth/authTypes';
import {
  parseData,
  UnauthorizedError,
  UnhandledStatus,
} from '@collab/utils/api';

import { backendApiBaseUrl } from 'libs/api';

export const getLoginUrl = async (params: LoginParams): Promise<string> => {
  const searchParams = toSearchParams(params);

  const response = await fetch(
    `${backendApiBaseUrl}/auth/login?${searchParams}`,
  );

  const data = await parseData(response, 200);

  return data.loginUrl;
};

export const logout = async (refreshToken: string): Promise<void> => {
  const response = await fetch(`${backendApiBaseUrl}/auth/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refreshToken }),
  });

  if (response.status !== 204) {
    throw await UnhandledStatus.fromResponse(response);
  }
};

export const tokenExchange = async (body: ExchangeType): Promise<AuthToken> => {
  const response = await fetch(`${backendApiBaseUrl}/auth/token/exchange`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      codeVerifier: body.codeVerifier,
      redirectUri: body.redirectUri,
      code: body.code,
    }),
  });

  const data = await parseData(response, 200);

  return normalizeAuthToken(data.token);
};

export const refreshTokens = async (
  refreshToken: string,
): Promise<AuthToken> => {
  const response = await fetch(`${backendApiBaseUrl}/auth/token/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refreshToken }),
  });

  if (response.status === 401) {
    throw new UnauthorizedError('Refreshing the access token failed');
  }

  const data = await parseData(response, 200);

  return normalizeAuthToken(data.token);
};
