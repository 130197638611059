import { NextComponentType } from 'next';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';
import { StyleRenderer } from 'vcc-ui';

import useAppInit from '@collab/hooks/useAppInit';
import { ComponentWithPageMods } from '@collab/layouts/PageLayout';
import { MenuItemModel } from '@collab/organisms/NavMenu';
import { GTMTagScript, OneTrustScript } from '@collab/setup/3rd-party-scripts';
import { getMenuContentFromHTML } from '@collab/setup/menuContentHTML';
import { getStyleRenderer } from '@collab/style/getStyleRenderer';
import { gtmMetrics } from '@collab/utils/analytics';

import { gtmId, oneTrust } from 'config/config';
import AppProviders from 'features/AppProviders';
import { DefaultSeo } from 'libs/seo/PageSeo';

import '@collab/style/oipCustomStyles.css';
import '@collab/organisms/NavMenu/menuBaseStyles.css';

type DesignPortalProps = {
  Component: NextComponentType & ComponentWithPageMods;
  pageProps: AppProps['pageProps'];
  renderer: StyleRenderer;
  menuItems: MenuItemModel[] | undefined;
};

const browserStyleRenderer = getStyleRenderer();

const DesignPortal = ({
  Component,
  pageProps,
  // renderer will only be defined server-side, hence pass a browser
  // specific render instance
  renderer = browserStyleRenderer,
  menuItems,
}: DesignPortalProps) => {
  useAppInit();

  // On the client the menu is baked into the HTML, server we get it as props.
  const menu = menuItems ?? getMenuContentFromHTML();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <OneTrustScript
        optanonUrl={oneTrust.optanonUrl}
        dataDomainScript={oneTrust.dataDomainScript}
      />
      <GTMTagScript gtmId={gtmId} />
      <DefaultSeo />
      <AppProviders
        renderer={renderer}
        menuItems={menu}
        pageMods={Component.pageMods}
      >
        <Component {...pageProps} />
      </AppProviders>
    </>
  );
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  gtmMetrics(metric);
}

export default DesignPortal;
