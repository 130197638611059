import dynamic from 'next/dynamic';
import { useCallback } from 'react';

import { usePopup } from '@collab/features/popup';
import { ErrorProvider } from '@collab/libs/errors';
import { UnauthorizedError } from '@collab/utils/api';
import { removeTrailingSlash } from '@collab/utils/getValidRedirectUrl';

import useForceLogout from 'libs/users/useForceLogout';
import protectedUrls from 'utils/protectedUrls';

const ErrorPopup = dynamic(() => import('./ErrorPopup'));

const ErrorConfig: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const forceLogout = useForceLogout();
  const { openPopup, closePopup } = usePopup();

  const openErrorPopup = () => {
    openPopup({
      title: 'Your session has ended',
      components: <ErrorPopup close={closePopup} />,
    });
  };

  const errorHandler = useCallback(
    (error: unknown): boolean => {
      if (error instanceof UnauthorizedError) {
        forceLogout();

        if (!isProtected(window.location.pathname)) {
          openErrorPopup();
        }

        return true;
      }

      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <ErrorProvider errorHandler={errorHandler}>{children}</ErrorProvider>;
};

const isProtected = (path: string) =>
  protectedUrls.includes(removeTrailingSlash(path));

export default ErrorConfig;
